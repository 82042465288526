<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="overflow-hidden">
          <v-toolbar flat color="white">
            <v-toolbar-title class="font-weight-bold" color="grey darken-2">
              GENERAR REPORTE
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text-h6 green--text">Filtrar por</span>
            <v-row class="mx-0">
              <v-col cols="8" sm="12" lg="8" md="12">
                <v-row>
                  <v-col cols="12" sm="12" lg="4" md="4">
                    <v-autocomplete
                      :items="municipalities"
                      item-text="descripcion"
                      item-value="municipio_id"
                      single
                      v-model="report.municipality_id"
                      label="Municipio"
                      prepend-icon="mdi-map-marker"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" lg="4" md="4">
                    <v-autocomplete
                      :items="territories"
                      item-text="descripcion"
                      item-value="territorio_id"
                      single
                      v-model="report.territory_id"
                      label="Territorio"
                      prepend-icon="mdi-map-marker"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" lg="4" md="4">
                    <v-autocomplete
                      :items="sidewalks"
                      item-text="descripcion"
                      item-value="vereda_id"
                      single
                      v-model="report.sidewalk_id"
                      label="Comunidad o vereda"
                      prepend-icon="mdi-map-marker"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" lg="4" md="4">
                    <v-text-field
                      label="Rango de fecha de eventos"
                      prepend-icon="mdi-calendar-month"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" lg="4" md="4">
                    <v-autocomplete
                      :items="studyLevels"
                      item-text="descripcion"
                      item-value="nivelestudio_id"
                      single
                      v-model="report.level_of_study_id"
                      label="Tipo de eventos"
                      prepend-icon="mdi-bookmark-box-multiple"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" lg="4" md="4">
                    <v-autocomplete
                      :items="activities"
                      item-text="descripcion"
                      item-value="ocupacion_id"
                      single
                      v-model="report.occupation_id"
                      label="Estado General"
                      prepend-icon="mdi-map-marker"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" lg="4" md="4">
                    <v-autocomplete
                      :items="economicIncome"
                      item-text="descripcion"
                      item-value="ingresoeconomico_id"
                      single
                      v-model="report.economic_income_id"
                      label="Estado de Gestion"
                      prepend-icon="mdi-currency-usd"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" lg="4" md="4">
                    <v-text-field
                      label="Rango de edad afectados"
                      prepend-icon="mdi-home"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="12"
                    lg="4"
                    md="12"
                    class="d-flex flex-column justify-end"
                  >
                    <div>
                      <v-btn rounded color="primary" dark class="mb-5">
                        GENERAR REPORTE
                        <v-icon>mdi-chart-line</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncDataSource } from "@/services/dataSource";

export default {
  name: "Reports.vue",
  data() {
    return {
      municipalities: [],
      territories: [],
      sidewalks: [],
      studyLevels: [],
      activities: [],
      economicIncome: [],
      report: {
        municipality_id: "",
        territory_id: "",
        sidewalk_id: "",
        level_of_study_id: "",
        occupation_id: "",
        economic_income_id: "",
      },
    };
  },
  async created() {
    const response = await syncDataSource();
    this.municipalities = response.data.data.results.Municipio;
    this.territories = response.data.data.results.Territorio;
    this.sidewalks = response.data.data.results.Vereda;
    this.studyLevels = response.data.data.results.Nivelestudio;
    this.activities = response.data.data.results.Ocupacion;
    this.economicIncome = response.data.data.results.Ingresoeconomico;
  },
};
</script>

<style scoped></style>
